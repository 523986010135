import React from 'react';
import PropTypes from 'prop-types';
import OpenStreetMap, { TrainingsSessionShape } from '../../OpenStreetMap';
import Section from '../Section';

import styles from './TrainingsLocations.module.css';

const TrainingsLocations = ({ trainingsSessions }) => {
  return (
    <Section className={styles.Section} fullWidth neutral>
      <h2>Training</h2>
      <OpenStreetMap trainingsSessions={trainingsSessions} height={330} />
    </Section>
  );
};

TrainingsLocations.propTypes = {
  trainingsSessions: PropTypes.arrayOf(PropTypes.shape(TrainingsSessionShape)),
};

export default TrainingsLocations;
