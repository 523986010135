import React from 'react';
import PropTypes from 'prop-types';

import Section from '../Section';
import TeamTrainer, { TeamTrainerShape } from './TeamTrainer';

import styles from './TeamTrainers.module.css';

const TeamTrainers = ({ trainers, inverse, neutral }) => {
  return (
    <Section headline="Trainer" inverse={inverse} neutral={neutral}>
      <article className={styles.Trainer}>
        {trainers.map(trainer => (
          <TeamTrainer key={trainer.forename} trainer={trainer} />
        ))}
      </article>
    </Section>
  );
};

TeamTrainers.propTypes = {
  trainers: PropTypes.arrayOf(PropTypes.shape(TeamTrainerShape)),
  neutral: PropTypes.bool,
  inverse: PropTypes.bool,
};

export default TeamTrainers;
