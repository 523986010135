import React from 'react';
import PropTypes from 'prop-types';

import styles from './TeamTrainer.module.css';

const TeamTrainer = ({ trainer }) => {
  return (
    <div className={styles.trainer}>
      <div className={styles.teaserImg} />
      <div className={styles.teaserFName}>{trainer.forename}</div>
      <div className={styles.teaserSName}>{trainer.surname}</div>
    </div>
  );
};

const TeamTrainerShape = {
  forename: PropTypes.string,
  surname: PropTypes.string,
};

TeamTrainer.propTypes = {
  trainer: PropTypes.shape(TeamTrainerShape),
};

export default TeamTrainer;
export { TeamTrainerShape };
