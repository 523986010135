import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { get } from 'lodash';
import { graphql } from 'gatsby';

import Header from '../components/Header';
import TeamPlayers from '../components/sections/TeamPlayers';
import TeamTrainers from '../components/sections/TeamTrainers';
import TrainingsLocations from '../components/sections/TrainingsLocations';
import HtmlSection from '../components/sections/HtmlSection';
import Layout from '../components/Layout';

class TeamTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');
    const trainers = get(
      this.props,
      'data.markdownRemark.frontmatter.daten.trainers',
      []
    );
    const withTrainers = trainers.length > 0;
    return (
      <Layout>
        <Helmet
          title={`Mannschaft :: ${post.frontmatter.title} | ${siteTitle}`}
        />
        <Header title={post.frontmatter.title} />
        <TrainingsLocations
          trainingsSessions={get(
            this.props,
            'data.markdownRemark.frontmatter.daten.trainingsSessions',
            []
          )}
        />
        {withTrainers && <TeamTrainers trainers={trainers} inverse />}
        <TeamPlayers
          players={get(
            this.props,
            'data.markdownRemark.frontmatter.daten.players',
            []
          )}
          neutral={withTrainers}
        />
        <HtmlSection html={post.html} />
      </Layout>
    );
  }
}

TeamTemplate.propTypes = {
  data: PropTypes.object,
};

export default TeamTemplate;

export const pageQuery = graphql`
  query TeamTemplate($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        order
        daten {
          trainingsSessions {
            geoCoordinates
            dayOfWeek
            startTime
            endTime
            location
          }
        }
      }
    }
  }
`;
