import React from 'react';
import PropTypes from 'prop-types';

import styles from './TeamPlayer.module.css';

const TeamPlayer = ({
  player: { num, forename, surname, image, positions },
}) => {
  return (
    <div className={styles.Player}>
      <div className={styles.Num}>{num}</div>
      <div className={styles.ImageWrapper}>
        {image ? (
          <img src={image} alt="Spielerfoto" />
        ) : (
          <div className={styles.NoImage}> ? </div>
        )}
      </div>
      <div className={styles.Name}>
        <div className={styles.Forename}>{forename}</div>
        <div className={styles.Surname}>{surname}</div>
      </div>
      {positions.length > 0 && (
        <div className={styles.Positions}>
          <span className={styles.Label}>Positionen:&nbsp;</span>
          {positions.join(', ')}
        </div>
      )}
    </div>
  );
};

const TeamPlayerShape = {
  num: PropTypes.number,
  forename: PropTypes.string,
  surname: PropTypes.string,
  image: PropTypes.string,
};

TeamPlayer.propTypes = {
  player: PropTypes.shape(TeamPlayerShape),
};

export default TeamPlayer;
export { TeamPlayerShape };
