/* globals L */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import moment from 'moment';
import 'moment/locale/de';
import iconUrl from './marker-icon.png';
import shadowUrl from './marker-shadow.png';
import iconRetinaUrl from './marker-icon-2x.png';

class OpenStreetMap extends Component {
  constructor(props) {
    super(props);
    this.addTrainingsSession = this.addTrainingsSession.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const L = require('leaflet');

      const map = (this.map = L.map('map', {
        scrollWheelZoom: false,
        closePopupOnClick: false,
      }).setView([52.399, 13.2327], 13));
      L.tileLayer(
        'https://api.mapbox.com/styles/v1/mkruschke/citxf8mnc00a02iqiws4sukgq/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWtydXNjaGtlIiwiYSI6ImNpdHhlZW90djAwMm40NnA2djY0bDQ3Z28ifQ.I3ZSrXlHDbvMgcEQpiaUVA',
        {
          minZoom: 13,
          maxZoom: 16,
        }
      ).addTo(map);

      // This helps to let the user scolling without get catch be the zoom
      // functionallity from the map
      map.on('click', function() {
        if (map.scrollWheelZoom.enabled()) {
          map.scrollWheelZoom.disable();
        } else {
          map.scrollWheelZoom.enable();
        }
      });

      map.on('focus', function() {
        this.scrollWheelZoom.enable();
      });
      map.on('blur', function() {
        this.scrollWheelZoom.disable();
      });

      this.props.trainingsSessions.map(this.addTrainingsSession);
    }
  }

  addTrainingsSession(session) {
    // here I coudn't resolve a bundle and leaflet path issue with the images
    // and so I decided  tocopied the images to the assets folder
    // and set the reference to themby hand
    const myIcon = L.icon({
      iconUrl,
      shadowUrl,
      iconRetinaUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      tooltipAnchor: [16, -28],
      shadowSize: [41, 41],
    });

    const marker = L.marker(session.geoCoordinates, { icon: myIcon }).addTo(
      this.map
    );
    const dayOfWeek = moment()
      .weekday(session.dayOfWeek)
      .format('dd');

    marker
      .bindPopup(
        `<div class="time">${dayOfWeek} ${session.startTime} - ${
          session.endTime
        } </div> <div class="location">${session.location}</div>`,
        { autoClose: false }
      )
      .openPopup();
  }

  render() {
    const { height = 200 } = this.props;

    return (
      <div id="map" style={{ height: `${height}px` }}>
        {' '}
        Map is loading...{' '}
      </div>
    );
  }
}

const TrainingsSessionShape = {
  geoCoordinates: PropTypes.array,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  dayOfWeek: PropTypes.number,
};

OpenStreetMap.propTypes = {
  height: PropTypes.number,
  L: PropTypes.object,
  trainingsSessions: PropTypes.arrayOf(PropTypes.shape(TrainingsSessionShape)),
};

export default OpenStreetMap;
export { TrainingsSessionShape };
