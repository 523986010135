import React from 'react';
import PropTypes from 'prop-types';

import Section from '../Section';
import TeamPlayer, { TeamPlayerShape } from './TeamPlayer';

import styles from './TeamPlayers.module.css';

const TeamPlayers = ({ players, neutral }) => {
  return (
    players.length > 0 && (
      <Section headline="Mannschaftsliste" neutral={neutral}>
        <article className={styles.Players}>
          {players.map(player => (
            <TeamPlayer
              key={player.num || `${player.forename}${player.surname}`}
              player={player}
            />
          ))}
        </article>
      </Section>
    )
  );
};

TeamPlayers.propTypes = {
  players: PropTypes.arrayOf(PropTypes.shape(TeamPlayerShape)),
  neutral: PropTypes.bool,
  inverse: PropTypes.bool,
};

export default TeamPlayers;
